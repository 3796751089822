import Vue from 'vue'
import store from '@/store'
import urlGenerator from '@/lib/url-generator'

export default {
  search(queryParams) {
    return Vue.http.get(
      urlGenerator(`${store.getters['environment/atomicCustomerAPI']}/company/search`, queryParams)
    )
  },
}
