import Vue from 'vue'
import Vuex from 'vuex'
import ActivityModule from './activity'
import CustomerModule from './customer'
import CompanyModule from './company'
import EmulatorModule from './emulator'
import UserModule from './user'
import TaskModule from './task'
import NavigationModule from './navigation'
import EnvironmentModule from './environment'
import ScenarioSimulatorModule from './scenario-simulator'
import Signup from './signup'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    activity: ActivityModule,
    customer: CustomerModule,
    company: CompanyModule,
    emulator: EmulatorModule,
    environment: EnvironmentModule,
    navigation: NavigationModule,
    task: TaskModule,
    user: UserModule,
    scenarioSimulator: ScenarioSimulatorModule,
    signup: Signup,
  },
})
