<template>
  <div class="rules">
    <div v-for="(rule, index) in searchRules" :key="index" class="rule-container">
      <div
        class="rule-header d-flex justify-content-between align-items-center p-3"
        @click="toggleRule(index)"
      >
        <div class="d-flex align-items-center">
          <div class="me-2 cursor-pointer">
            <i
              class="fas fa-chevron-right transition-transform"
              :class="{ 'rotate-90': openRules[index] }"
            ></i>
          </div>
          <div class="d-flex align-items-center">
            <input
              type="text"
              class="form-control form-control me-2"
              :placeholder="`Experience name`"
              :value="rule.name"
              @input="(e) => updateRuleName(index, e.target.value)"
              @blur="emitChanges"
              @click.stop
              style="width: 200px"
            />
            <div v-if="rule.product" class="search-rule__product">
              <span class="search-rule__product-icon">
                <img
                  :src="`/images/icon-${rule.product}.svg`"
                  :alt="rule.product"
                  class="search-rule__product-img"
                />
              </span>
              {{ rule.product.charAt(0).toUpperCase() + rule.product.slice(1) }}
            </div>
          </div>
        </div>
        <div class="search-rule__actions">
          <button
            v-if="canShowCopyButton(rule)"
            class="search-rule__btn search-rule__btn--copy"
            @click.stop="copyRuleId(rule._id)"
          >
            <i class="fas fa-copy"></i>
            Copy ID
          </button>
          <button class="search-rule__btn search-rule__btn--delete" @click.stop="removeRule(index)">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>

      <div class="search-rule__body" :class="{ 'search-rule__body--open': openRules[index] }">
        <div v-if="canShowCopyButton(rule)" class="d-flex">
          <i class="fas fa-info-circle text-info me-2 mt-1"></i>
          <div>
            Copy the search experience ID and use it in your Transact configuration search parameter
            like this:
            <code style="background: #e9ecef; padding: 2px 4px; border-radius: 4px">
              search: { ruleId: "{{ rule._id }}" }
            </code>
          </div>
        </div>
        <div>
          <h4 class="mb-2">Product</h4>
          <div class="product-toggle">
            <button
              v-for="option in productOptions"
              :key="option.value"
              class="product-toggle__btn"
              :class="{
                'product-toggle__btn--active': rule.product === option.value,
              }"
              @click.stop="onProductChange(option.value, index)"
            >
              <span class="product-toggle__icon">
                <img
                  :src="`/images/icon-${option.value}.svg`"
                  :alt="option.label"
                  class="product-toggle__img"
                />
              </span>
              {{ option.label }}
            </button>
          </div>
        </div>
        <CustomSwitch
          :value="rule.disableSearch"
          label="Disable Employer/Payroll Provider Search"
          @input="(value) => toggleDisableSearch(value, index)"
        >
          <template #helperText>
            <div class="small mt-2">
              When enabled, the ability to search within Transact will be hidden.
            </div>
          </template>
        </CustomSwitch>
        <div v-if="!rule.disableSearch">
          <div>
            <h4 class="mb-0">Excluded Employers/Payroll Providers (optional)</h4>
            <small class="text-muted d-block pt-2 pb-2">
              Excluded employers and payroll providers will not be shown in search results.
            </small>
          </div>
          <v-select
            v-model="rule.excludedCompanies"
            :options="displayCompanies"
            :get-option-label="(company) => company.name"
            :get-option-key="(company) => company._id"
            @search="(query, loading) => onCompanySearch(query, loading, index)"
            @input="(companies) => onExcludedSelectionChange(companies, index)"
            multiple
          />
        </div>

        <div>
          <div>
            <div>
              <h4 class="mb-0">Search Grid</h4>
              <small class="text-muted d-block pt-2 pb-2">
                Search for employers and payroll providers to add. THen drag them to arrange them in
                the grid.
              </small>
            </div>
          </div>

          <div>
            <template v-if="rule.suggestedCompanies && rule.suggestedCompanies.length >= 28">
              <div class="alert alert-info">
                You have reached your limit of companies. You may add more by deleting any
                previously selected companies.
              </div>
            </template>
            <v-select
              v-else
              :ref="`companySelect${index}`"
              :options="displayCompanies"
              :reduce="(company) => company._id"
              placeholder="Search and select employers and payroll providers"
              :get-option-label="
                (company) =>
                  company.subtext ? `${company.name} (${company.subtext})` : company.name
              "
              :get-option-key="(company) => company._id"
              @search="(query, loading) => onCompanySearch(query, loading, index)"
              @input="(companyId) => addCompany(companyId, index)"
              @open="clearSearchResults"
              :value="null"
            />
          </div>

          <div class="ordered-list">
            <div class="company-grid">
              <draggable
                v-model="rule.suggestedCompanies"
                group="companies"
                :animation="200"
                ghost-class="ghost"
                class="grid-container"
                @change="emitChanges"
              >
                <div v-for="slotIndex in 28" :key="`${index}-${slotIndex}`" class="grid-slot">
                  <div
                    v-if="rule.suggestedCompanies && rule.suggestedCompanies[slotIndex - 1]"
                    class="company-item"
                  >
                    <button
                      type="button"
                      class="btn-remove"
                      @click="
                        removeCompany(rule.suggestedCompanies[slotIndex - 1], index, slotIndex - 1)
                      "
                      style="position: absolute; top: 0.5rem; right: 0.5rem"
                    >
                      X
                    </button>
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                      "
                    >
                      <div
                        class="company-logo-wrap"
                        :style="{
                          backgroundColor: getCompanyBackground(
                            rule.suggestedCompanies[slotIndex - 1]
                          ),
                        }"
                      >
                        <div class="company-logo-container">
                          <div
                            v-if="
                              rule.suggestedCompanies[slotIndex - 1].branding &&
                              rule.suggestedCompanies[slotIndex - 1].branding.logo &&
                              rule.suggestedCompanies[slotIndex - 1].branding.logo.url
                            "
                          >
                            <img
                              class="company-logo-img"
                              :src="rule.suggestedCompanies[slotIndex - 1].branding.logo.url"
                            />
                          </div>
                          <div v-else>
                            <div
                              class="company-logo-fallback"
                              :style="{
                                color: getCompanyTextColor(rule.suggestedCompanies[slotIndex - 1]),
                              }"
                            >
                              {{
                                rule.suggestedCompanies[slotIndex - 1].name.charAt(0).toUpperCase()
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <span class="company-name">
                        {{ rule.suggestedCompanies[slotIndex - 1].name }}
                      </span>
                    </div>
                  </div>
                  <div v-else class="empty-slot">Drop company here</div>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { capitalize } from 'lodash-es'
import { PRODUCTS, SCOPES } from '@atomicfi/constants-shared'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import draggable from 'vuedraggable'
import CustomSwitch from '@/components/MainContent/CustomSwitch'

export default {
  name: 'SearchRules',
  props: {
    data: Array,
  },
  components: {
    draggable,
    CustomSwitch,
  },
  data: () => ({
    productOptions: [
      {
        value: PRODUCTS.DEPOSIT,
        label: capitalize(PRODUCTS.DEPOSIT),
        scopes: SCOPES.USER_LINK,
      },
      {
        value: PRODUCTS.VERIFY,
        label: capitalize(PRODUCTS.VERIFY),
        scopes: SCOPES.USER_LINK,
      },
      {
        value: PRODUCTS.SWITCH,
        label: capitalize(PRODUCTS.SWITCH),
        scopes: SCOPES.PAY_LINK,
      },
      {
        value: 'tax',
        label: 'Tax',
        scopes: SCOPES.USER_LINK,
      },
    ],
    searchRules: [
      {
        product: '',
        disableSearch: false,
        suggestedCompanies: [],
        excludedCompanies: [],
      },
    ],
    openRules: {},
    colorList: [
      {
        text: 'rgba(71, 181, 212, 1)',
        bg: 'rgba(71, 181, 212, 0.2)',
      },
      {
        text: 'rgba(129, 72, 244, 1)',
        bg: 'rgba(129, 72, 244, 0.2)',
      },
      {
        text: 'rgba(25, 221, 139, 1)',
        bg: 'rgba(25, 221, 139, 0.2)',
      },
      {
        text: 'rgba(244, 62, 189, 1)',
        bg: 'rgba(244, 62, 189, 0.2)',
      },
    ],
  }),
  watch: {
    data: {
      handler(newData, oldData) {
        if (newData) {
          const isNewRule = oldData && newData.length > oldData.length
          const isSameLength = oldData && newData.length === oldData.length

          this.searchRules = newData

          if (isNewRule) {
            this.searchRules.forEach((_, index) => {
              const isNewRule = index === newData.length - 1
              this.$set(this.openRules, index, isNewRule)
            })
          } else if (!isSameLength) {
            this.searchRules.forEach((_, index) => {
              this.$set(this.openRules, index, false)
            })
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('company', ['displayCompanies']),
    ...mapState('customer', ['activeCustomer']),
  },
  methods: {
    ...mapActions('company', ['searchCompanies']),
    ...mapMutations('company', ['clearSearchResults']),
    emitChanges() {
      const cleanRules = this.searchRules.map((rule) => ({
        ...rule,
        suggestedCompanies: Array.isArray(rule.suggestedCompanies)
          ? rule.suggestedCompanies.filter(Boolean)
          : [],
      }))

      this.$emit('input', cleanRules)
      this.$store.dispatch('customer/updateCustomer', {
        'features.searchRules': this.searchRules,
      })
    },
    toggleRule(index) {
      this.$set(this.openRules, index, !this.openRules[index])
    },
    updateRuleName(index, value) {
      this.$set(this.searchRules[index], 'name', value)
    },
    canShowCopyButton(rule) {
      return (
        rule._id &&
        rule.product &&
        (rule.suggestedCompanies.length > 0 || rule.excludedCompanies.length > 0)
      )
    },
    async copyRuleId(id) {
      if (!id) return

      try {
        if (window?.navigator?.clipboard) {
          await navigator.clipboard.writeText(id)
          this.$toasted.success('Rule ID copied to clipboard')
        } else {
          // Fallback for browsers without clipboard API
          const textarea = document.createElement('textarea')
          textarea.value = id
          document.body.appendChild(textarea)
          textarea.select()
          document.execCommand('copy')
          document.body.removeChild(textarea)
          this.$toasted.success('Rule ID copied to clipboard')
        }
      } catch (error) {
        this.$toasted.error('Failed to copy Rule ID')
      }
    },
    onProductChange(value, index) {
      // Clear search results
      this.clearSearchResults()
      if (this.searchRules[index]) {
        this.$set(this.searchRules[index], 'product', value)
        this.$set(this.searchRules[index], 'suggestedCompanies', [])
        this.$set(this.searchRules[index], 'excludedCompanies', [])
        this.emitChanges()
      }
    },
    toggleDisableSearch(value, index) {
      // Clear excluded companies when search is disabled
      if (value) {
        this.searchRules[index].excludedCompanies = []
      }

      this.$set(this.searchRules[index], 'disableSearch', value)
      this.emitChanges()
    },
    async onCompanySearch(query, loading, index) {
      if (query.length < 2) return
      loading(true)
      try {
        let product = this.searchRules[index].product
        let tags
        if (product === 'tax') {
          product = PRODUCTS.VERIFY
          tags = ['tax']
        }
        const productOption = this.productOptions.find((option) => option.value === product)
        await this.searchCompanies({
          query,
          product,
          tags,
          scopes: productOption?.scopes || SCOPES.USER_LINK,
        })
      } finally {
        loading(false)
      }
    },
    addCompany(companyId, ruleIndex) {
      if (!companyId) return

      const company = this.displayCompanies.find((company) => company._id === companyId)
      if (!company) return

      const cleanCompany = {
        _id: company._id,
        name: company.name,
        branding: company.branding,
      }

      const isDuplicate = this.searchRules[ruleIndex].suggestedCompanies?.some(
        (existingCompany) => existingCompany?._id === cleanCompany._id
      )

      if (isDuplicate) {
        this.$toasted.error(`${company.name} has already been added to this rule.`)
        return
      }

      if (!Array.isArray(this.searchRules[ruleIndex].suggestedCompanies)) {
        this.searchRules[ruleIndex].suggestedCompanies = []
      }

      this.searchRules[ruleIndex].suggestedCompanies.push(cleanCompany)
      this.emitChanges()

      const selectRef = this.$refs[`companySelect${ruleIndex}`]
      if (selectRef && selectRef[0]) {
        selectRef[0].clearValue()
        selectRef[0].$refs.search.value = ''
      }
    },

    onExcludedSelectionChange(companies, index) {
      this.searchRules[index].excludedCompanies = companies || []
      this.emitChanges()
    },

    removeCompany(company, ruleIndex, slotIndex) {
      if (!company) return

      const rule = this.searchRules[ruleIndex]
      if (!rule || !Array.isArray(rule.suggestedCompanies)) {
        return
      }

      const newSuggestedCompanies = rule.suggestedCompanies
        .map((company, index) => (index === slotIndex ? null : company))
        .filter((company) => company !== null)

      this.$set(this.searchRules, ruleIndex, {
        ...rule,
        suggestedCompanies: newSuggestedCompanies,
      })

      this.emitChanges()
    },
    initializeSearchRules() {
      const customerSearchRules = this.activeCustomer?.features?.searchRules

      if (customerSearchRules?.length) {
        this.searchRules = customerSearchRules

        customerSearchRules.forEach((rule) => {
          rule.suggestedCompanies?.forEach((id) => {
            const company = this.displayCompanies.find((company) => company._id === id)
            if (company) {
              this.$store.commit('company/addSelectedCompany', company)
            }
          })

          rule.excludedCompanies?.forEach((id) => {
            const company = this.displayCompanies.find((company) => company._id === id)
            if (company) {
              this.$store.commit('company/addExcludedCompany', company)
            }
          })
        })
      }
    },
    removeRule(index) {
      this.searchRules.splice(index, 1)
      this.emitChanges()
    },
    getCompanyBackground(company) {
      if (company.branding?.logo?.backgroundColor || company.branding?.color) {
        return company.branding.logo.backgroundColor || company.branding.color
      }

      const colorIndex = this.getColorIndexFromId(company._id)
      return this.colorList[colorIndex].bg
    },

    getCompanyTextColor(company) {
      if (company.branding?.logo?.url) return 'inherit'
      const colorIndex = this.getColorIndexFromId(company._id)
      return this.colorList[colorIndex].text
    },

    getColorIndexFromId(id) {
      const sum = id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)
      return sum % this.colorList.length
    },
  },
  mounted() {
    this.initializeSearchRules()
  },
}
</script>

<style scoped lang="scss">
.form-label {
  font-weight: 500;
  color: #495057;
}

.form-group {
  position: relative;
}

.company-grid {
  margin: 1rem 0;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(7, 1fr);
  gap: 0.5rem;
  background: #f8f9fa;
  width: 100%;
}

.grid-slot {
  aspect-ratio: 2;
  min-height: 120px;
  border: 2px dashed #dee2e6;
  border-radius: 8px;
  transition: all 0.2s ease;
  overflow: hidden;
  width: 100%;
  position: relative;

  &:has(.company-item) {
    border: none;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.1);
  }
}

.company-item {
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  background: white;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  cursor: move;
  overflow: hidden;
  min-width: 0;
}

.company-logo-wrap {
  width: 62px;
  height: 62px;
  min-width: 62px;
  min-height: 62px;
  border-radius: 15.5px;
  font-size: 31px;
}

.company-logo-container {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 8px;
  width: 100%;
  height: 100%;
}

.company-logo-img {
  display: block;
  height: 100%;
  object-fit: scale-down;
  position: relative;
  transition: none;
  width: 100%;
}

.company-name {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  font-size: 0.875rem;
  margin-top: 10px;
  text-align: center;
}

.btn-remove {
  flex: 0 0 auto;
  padding: 0.25rem;
  background: none;
  border: none;
  color: #dc3545;
  opacity: 0.5;
  transition: opacity 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
}

.empty-slot {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #adb5bd;
  font-size: 0.75rem;
  text-align: center;
}

.ghost {
  opacity: 0.5;
  background: #e9ecef;
}

hr {
  border-color: #e9ecef;
  margin: 2rem 0;
}

.rule-container {
  border-radius: 8px;
  background-color: #f8f9fa;

  &:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
  }
}

.rule-header {
  cursor: pointer;

  .form-control {
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.25rem 0.5rem;
    height: auto;

    &:hover {
      border-color: #dee2e6;
    }

    &:focus {
      background-color: white;
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  &:hover {
    .form-control {
      border-color: #dee2e6;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.transition-transform {
  transition: transform 0.2s ease;
}

.rotate-90 {
  transform: rotate(90deg);
}

.card-body {
  transition: all 0.3s ease-in-out;
}
.company-logo-fallback {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
}

.rules {
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.product-toggle {
  display: flex;
  gap: 1rem;

  &__btn {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 1rem;
    background: #fff;
    border: 2px solid #e9ecef;
    border-radius: 12px;
    color: #495057;
    font-weight: 500;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      background: #f1f3f5;
      border-color: #dee2e6;
    }

    &--active {
      background: #4b39ef0f;
      border-color: #4b39ef;
      color: #4b39ef;

      &:hover {
        background: #4b39ef1a;
        border-color: #4b39ef;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    padding: 6px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

// Optional: Add responsive styles for smaller screens
@media (max-width: 768px) {
  .product-toggle {
    flex-direction: column;
    gap: 0.5rem;

    &__btn {
      width: 100%;
    }
  }
}

.search-rule {
  &__product {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.375rem 0.75rem;
    background: #4b39ef0f;

    border-radius: 6px;

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
  }

  &__product-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    padding: 4px;
  }

  &__product-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__actions {
    display: flex;
    gap: 0.5rem;
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s ease;

    i {
      font-size: 0.875rem;
    }

    &--copy {
      background: #f8f9fa;
      border-color: #dee2e6;
      color: #495057;

      &:hover {
        background: #e9ecef;
        border-color: #ced4da;
      }

      // Add success state for copy feedback
      &.copied {
        background: #d3f9d8;
        border-color: #8ce99a;
        color: #2b8a3e;
      }
    }

    &--delete {
      background: none;
      color: #e03131;

      &:hover {
        background: none;
        color: #c92a2a;
      }
    }
  }

  &__body {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
    padding: 0px 16px 0px 32px;
    transform: translateY(-10px);
    display: flex;
    flex-direction: column;
    gap: 40px;

    &--open {
      max-height: 2000px; // Adjust based on your content height
      opacity: 1;
      padding: 32px 16px 32px 32px;
      transform: translateY(0);
      transition: all 0.5s ease-in;
    }
  }
}
</style>

<style lang="scss">
.v-select {
  .vs__dropdown-toggle {
    padding: 4px 0;
    border-color: #dee2e6;
    background-color: white;
  }

  .vs__selected {
    margin: 0 2px;
  }

  .vs__search {
    margin: 0;
    color: #495057; // Default text color
  }

  .vs__search::placeholder {
    color: #adb5bd; // Lighter gray for placeholder
  }

  .vs__placeholder {
    color: #adb5bd; // Lighter gray for placeholder
  }

  // Optional: Style the dropdown options
  .vs__dropdown-menu {
    background: white;
    border-color: #dee2e6;
  }

  .vs__dropdown-option {
    color: #495057;
  }

  .vs__dropdown-option--highlight {
    background: #4b39ef0f;
    color: #4b39ef;
  }
}
</style>
