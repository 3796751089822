var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("EmulatorSectionHeader", { attrs: { title: "Initialization" } }),
      _c("div", [
        _c("div", { staticClass: "w-100 text-center mb-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-white w-100 scope-option",
              on: { click: _vm.removeScope },
            },
            [
              _c("img", {
                attrs: { src: "/images/emulator/" + _vm.scope.name + ".svg" },
              }),
              _c("span", [_vm._v(_vm._s(_vm.scope.label))]),
              _vm.scopes.length > 1
                ? _c("ShuffleIcon", {
                    staticClass: "ms-sm-2 text-muted scope-button-icon",
                    attrs: { size: "15" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm.scope.products.length > 1
          ? _c("div", { staticClass: "section" }, [
              _c("label", { staticClass: "mb-1" }, [_vm._v("Use Case")]),
              _c("p", { staticClass: "small text-muted mb-3" }, [
                _vm._v("What tasks do you want to execute?"),
              ]),
              _c(
                "div",
                { staticClass: "btn-group-toggle row gx-2 mb-4" },
                _vm._l(_vm.scope.products, function (product) {
                  return _c(
                    "div",
                    { key: product.name, staticClass: "col-12 my-1" },
                    [
                      product.generallyAvailable || _vm.featureUniqueForAtomic
                        ? _c("EmulatorProductSelector", {
                            attrs: { product: product, products: _vm.products },
                            on: { toggle: _vm.toggleProduct },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
        _vm.productsIncludeDeposit
          ? _c(
              "div",
              { staticClass: "section" },
              [
                _c("label", { staticClass: "mb-1" }, [
                  _vm._v("Deposit Accounts"),
                ]),
                _c("p", { staticClass: "small text-muted mb-3" }, [
                  _vm._v(
                    " Prompt the user to choose from their two available deposit accounts. "
                  ),
                ]),
                _c("CustomSwitch", {
                  attrs: {
                    value: _vm.useMultipleAccounts,
                    label: "Multiple Accounts",
                  },
                  on: { input: _vm.toggleUseMultipleAccounts },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.productsIncludeTax
          ? _c("div", { staticClass: "section" }, [
              _c("label", { staticClass: "mb-1" }, [_vm._v("Tax Forms")]),
              _c("p", { staticClass: "small text-muted mb-3" }, [
                _vm._v("Select which forms to pull."),
              ]),
              _c("div", { staticClass: "btn-group-toggle row gx-2 mb-4" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "label",
                    {
                      staticClass: "btn w-100 btn-white",
                      class: { active: _vm.taxForms.includes("w2s") },
                      on: {
                        click: function () {
                          return _vm.setTaxForms("w2s")
                        },
                      },
                    },
                    [_vm._v(" W-2s ")]
                  ),
                ]),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "label",
                    {
                      staticClass: "btn w-100 btn-white",
                      class: { active: _vm.taxForms.includes("1040s") },
                      on: {
                        click: function () {
                          return _vm.setTaxForms("1040s")
                        },
                      },
                    },
                    [_vm._v(" 1040s ")]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "section" }, [
          _c("label", { staticClass: "mb-1" }, [_vm._v("Starting Screen")]),
          _c("p", { staticClass: "small text-muted mb-3" }, [
            _vm._v("The first screen the user will see."),
          ]),
          _c("div", { staticClass: "btn-group-toggle row gx-2 mb-4" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "label",
                {
                  staticClass: "btn w-100 btn-white",
                  class: { active: !_vm.deeplink.step },
                  on: {
                    click: function () {
                      return _vm.setDeeplinkStep(undefined)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.startingScreenConsentTitle) + " ")]
              ),
            ]),
            _c("div", { staticClass: "col" }, [
              _c(
                "label",
                {
                  staticClass: "btn w-100 btn-white",
                  class: { active: _vm.deeplink.step === "search-company" },
                  on: {
                    click: function () {
                      return _vm.setDeeplinkStep("search-company")
                    },
                  },
                },
                [_vm._v(" Search ")]
              ),
            ]),
            !_vm.isScopePayLink
              ? _c("div", { staticClass: "col" }, [
                  _c(
                    "label",
                    {
                      staticClass: "btn w-100 btn-white",
                      class: { active: _vm.deeplink.step === "login-company" },
                      on: {
                        click: function () {
                          return _vm.setDeeplinkStep("login-company")
                        },
                      },
                    },
                    [_vm._v(" Login ")]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.deeplink.step === "login-company"
            ? _c("div", { staticClass: "form-group" }, [
                _c(
                  "h6",
                  {
                    staticClass: "header-pretitle mb-2",
                    attrs: { "data-v-75bf5b94": "" },
                  },
                  [_vm._v("Company ID")]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.deeplink.companyId,
                      expression: "deeplink.companyId",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    placeholder: "5ec2fbf4398fed000810a42c",
                    type: "text",
                  },
                  domProps: { value: _vm.deeplink.companyId },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.deeplink, "companyId", $event.target.value)
                    },
                  },
                }),
              ])
            : _vm._e(),
        ]),
        _vm.showSearchRulesSection
          ? _c("div", { staticClass: "section" }, [
              _c("label", { staticClass: "mb-1" }, [
                _vm._v("Search Experiences"),
              ]),
              _c(
                "div",
                [
                  _c("p", { staticClass: "small text-muted mb-3" }, [
                    _vm._v("Select a search experience to preview."),
                  ]),
                  _c("v-select", {
                    attrs: {
                      options: _vm.filteredSearchRules,
                      reduce: function (rule) {
                        return rule._id
                      },
                      "get-option-label": function (rule) {
                        return (
                          rule.name ||
                          "Rule " + (_vm.filteredSearchRules.indexOf(rule) + 1)
                        )
                      },
                      placeholder: "Select a search rule",
                    },
                    on: { input: _vm.onSearchRuleSelected },
                    model: {
                      value: _vm.selectedSearchRule,
                      callback: function ($$v) {
                        _vm.selectedSearchRule = $$v
                      },
                      expression: "selectedSearchRule",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("div", { staticClass: "section" }, [
          _c("label", { staticClass: "mb-1" }, [_vm._v("Language")]),
          _c("p", { staticClass: "small text-muted mb-3" }, [
            _vm._v("Language used for copy throughout Transact."),
          ]),
          _c(
            "div",
            { staticClass: "btn-group-toggle row gx-2 mb-4" },
            _vm._l(_vm.languages, function (language) {
              return _c("div", { key: language.code, staticClass: "col" }, [
                _c(
                  "label",
                  {
                    staticClass: "btn w-100 btn-white",
                    class: { active: _vm.settings.language === language.code },
                    on: {
                      click: function () {
                        return _vm.selectLanguage(language.code)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(language.flag) +
                        " " +
                        _vm._s(language.title) +
                        " "
                    ),
                  ]
                ),
              ])
            }),
            0
          ),
        ]),
        _vm.featureUniqueForAtomic &&
        !_vm.hasUserLinkContinuousAccessEnabled &&
        !_vm.isScopePayLink
          ? _c(
              "div",
              { staticClass: "section" },
              [
                _c("label", { staticClass: "mb-1" }, [_vm._v("Demo")]),
                _c("p", { staticClass: "small text-muted mb-3" }, [
                  _vm._v(
                    "Emulate TrueAuth experience for supported companies."
                  ),
                ]),
                _c("CustomSwitch", {
                  attrs: { value: _vm.useUplinkDemo, label: "TrueAuth" },
                  on: { input: _vm.toggleUseUplinkDemo },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }