var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rules" },
    _vm._l(_vm.searchRules, function (rule, index) {
      return _c("div", { key: index, staticClass: "rule-container" }, [
        _c(
          "div",
          {
            staticClass:
              "rule-header d-flex justify-content-between align-items-center p-3",
            on: {
              click: function ($event) {
                return _vm.toggleRule(index)
              },
            },
          },
          [
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("div", { staticClass: "me-2 cursor-pointer" }, [
                _c("i", {
                  staticClass: "fas fa-chevron-right transition-transform",
                  class: { "rotate-90": _vm.openRules[index] },
                }),
              ]),
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c("input", {
                  staticClass: "form-control form-control me-2",
                  staticStyle: { width: "200px" },
                  attrs: { type: "text", placeholder: "Experience name" },
                  domProps: { value: rule.name },
                  on: {
                    input: function (e) {
                      return _vm.updateRuleName(index, e.target.value)
                    },
                    blur: _vm.emitChanges,
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                }),
                rule.product
                  ? _c("div", { staticClass: "search-rule__product" }, [
                      _c("span", { staticClass: "search-rule__product-icon" }, [
                        _c("img", {
                          staticClass: "search-rule__product-img",
                          attrs: {
                            src: "/images/icon-" + rule.product + ".svg",
                            alt: rule.product,
                          },
                        }),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            rule.product.charAt(0).toUpperCase() +
                              rule.product.slice(1)
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "search-rule__actions" }, [
              _vm.canShowCopyButton(rule)
                ? _c(
                    "button",
                    {
                      staticClass: "search-rule__btn search-rule__btn--copy",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.copyRuleId(rule._id)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fas fa-copy" }),
                      _vm._v(" Copy ID "),
                    ]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "search-rule__btn search-rule__btn--delete",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.removeRule(index)
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-trash" })]
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "search-rule__body",
            class: { "search-rule__body--open": _vm.openRules[index] },
          },
          [
            _vm.canShowCopyButton(rule)
              ? _c("div", { staticClass: "d-flex" }, [
                  _c("i", {
                    staticClass: "fas fa-info-circle text-info me-2 mt-1",
                  }),
                  _c("div", [
                    _vm._v(
                      " Copy the search experience ID and use it in your Transact configuration search parameter like this: "
                    ),
                    _c(
                      "code",
                      {
                        staticStyle: {
                          background: "#e9ecef",
                          padding: "2px 4px",
                          "border-radius": "4px",
                        },
                      },
                      [
                        _vm._v(
                          ' search: { ruleId: "' + _vm._s(rule._id) + '" } '
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", [
              _c("h4", { staticClass: "mb-2" }, [_vm._v("Product")]),
              _c(
                "div",
                { staticClass: "product-toggle" },
                _vm._l(_vm.productOptions, function (option) {
                  return _c(
                    "button",
                    {
                      key: option.value,
                      staticClass: "product-toggle__btn",
                      class: {
                        "product-toggle__btn--active":
                          rule.product === option.value,
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onProductChange(option.value, index)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "product-toggle__icon" }, [
                        _c("img", {
                          staticClass: "product-toggle__img",
                          attrs: {
                            src: "/images/icon-" + option.value + ".svg",
                            alt: option.label,
                          },
                        }),
                      ]),
                      _vm._v(" " + _vm._s(option.label) + " "),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("CustomSwitch", {
              attrs: {
                value: rule.disableSearch,
                label: "Disable Employer/Payroll Provider Search",
              },
              on: {
                input: function (value) {
                  return _vm.toggleDisableSearch(value, index)
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "helperText",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "small mt-2" }, [
                          _vm._v(
                            " When enabled, the ability to search within Transact will be hidden. "
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
            !rule.disableSearch
              ? _c(
                  "div",
                  [
                    _vm._m(0, true),
                    _c("v-select", {
                      attrs: {
                        options: _vm.displayCompanies,
                        "get-option-label": function (company) {
                          return company.name
                        },
                        "get-option-key": function (company) {
                          return company._id
                        },
                        multiple: "",
                      },
                      on: {
                        search: function (query, loading) {
                          return _vm.onCompanySearch(query, loading, index)
                        },
                        input: function (companies) {
                          return _vm.onExcludedSelectionChange(companies, index)
                        },
                      },
                      model: {
                        value: rule.excludedCompanies,
                        callback: function ($$v) {
                          _vm.$set(rule, "excludedCompanies", $$v)
                        },
                        expression: "rule.excludedCompanies",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", [
              _vm._m(1, true),
              _c(
                "div",
                [
                  rule.suggestedCompanies &&
                  rule.suggestedCompanies.length >= 28
                    ? [
                        _c("div", { staticClass: "alert alert-info" }, [
                          _vm._v(
                            " You have reached your limit of companies. You may add more by deleting any previously selected companies. "
                          ),
                        ]),
                      ]
                    : _c("v-select", {
                        ref: "companySelect" + index,
                        refInFor: true,
                        attrs: {
                          options: _vm.displayCompanies,
                          reduce: function (company) {
                            return company._id
                          },
                          placeholder:
                            "Search and select employers and payroll providers",
                          "get-option-label": function (company) {
                            return company.subtext
                              ? company.name + " (" + company.subtext + ")"
                              : company.name
                          },
                          "get-option-key": function (company) {
                            return company._id
                          },
                          value: null,
                        },
                        on: {
                          search: function (query, loading) {
                            return _vm.onCompanySearch(query, loading, index)
                          },
                          input: function (companyId) {
                            return _vm.addCompany(companyId, index)
                          },
                          open: _vm.clearSearchResults,
                        },
                      }),
                ],
                2
              ),
              _c("div", { staticClass: "ordered-list" }, [
                _c(
                  "div",
                  { staticClass: "company-grid" },
                  [
                    _c(
                      "draggable",
                      {
                        staticClass: "grid-container",
                        attrs: {
                          group: "companies",
                          animation: 200,
                          "ghost-class": "ghost",
                        },
                        on: { change: _vm.emitChanges },
                        model: {
                          value: rule.suggestedCompanies,
                          callback: function ($$v) {
                            _vm.$set(rule, "suggestedCompanies", $$v)
                          },
                          expression: "rule.suggestedCompanies",
                        },
                      },
                      _vm._l(28, function (slotIndex) {
                        return _c(
                          "div",
                          {
                            key: index + "-" + slotIndex,
                            staticClass: "grid-slot",
                          },
                          [
                            rule.suggestedCompanies &&
                            rule.suggestedCompanies[slotIndex - 1]
                              ? _c("div", { staticClass: "company-item" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-remove",
                                      staticStyle: {
                                        position: "absolute",
                                        top: "0.5rem",
                                        right: "0.5rem",
                                      },
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeCompany(
                                            rule.suggestedCompanies[
                                              slotIndex - 1
                                            ],
                                            index,
                                            slotIndex - 1
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" X ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "column",
                                        "align-items": "center",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "company-logo-wrap",
                                          style: {
                                            backgroundColor:
                                              _vm.getCompanyBackground(
                                                rule.suggestedCompanies[
                                                  slotIndex - 1
                                                ]
                                              ),
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "company-logo-container",
                                            },
                                            [
                                              rule.suggestedCompanies[
                                                slotIndex - 1
                                              ].branding &&
                                              rule.suggestedCompanies[
                                                slotIndex - 1
                                              ].branding.logo &&
                                              rule.suggestedCompanies[
                                                slotIndex - 1
                                              ].branding.logo.url
                                                ? _c("div", [
                                                    _c("img", {
                                                      staticClass:
                                                        "company-logo-img",
                                                      attrs: {
                                                        src: rule
                                                          .suggestedCompanies[
                                                          slotIndex - 1
                                                        ].branding.logo.url,
                                                      },
                                                    }),
                                                  ])
                                                : _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "company-logo-fallback",
                                                        style: {
                                                          color:
                                                            _vm.getCompanyTextColor(
                                                              rule
                                                                .suggestedCompanies[
                                                                slotIndex - 1
                                                              ]
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              rule.suggestedCompanies[
                                                                slotIndex - 1
                                                              ].name
                                                                .charAt(0)
                                                                .toUpperCase()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "company-name" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                rule.suggestedCompanies[
                                                  slotIndex - 1
                                                ].name
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _c("div", { staticClass: "empty-slot" }, [
                                  _vm._v("Drop company here"),
                                ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h4", { staticClass: "mb-0" }, [
        _vm._v("Excluded Employers/Payroll Providers (optional)"),
      ]),
      _c("small", { staticClass: "text-muted d-block pt-2 pb-2" }, [
        _vm._v(
          " Excluded employers and payroll providers will not be shown in search results. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", [
        _c("h4", { staticClass: "mb-0" }, [_vm._v("Search Grid")]),
        _c("small", { staticClass: "text-muted d-block pt-2 pb-2" }, [
          _vm._v(
            " Search for employers and payroll providers to add. THen drag them to arrange them in the grid. "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }