import CompanyService from '@/services/CompanyService'

export default {
  namespaced: true,
  state: {
    searchResults: [],
    selectedCompanies: [],
    excludedCompanies: [],
  },
  getters: {
    displayCompanies: (state) => {
      const companiesMap = new Map()

      // Add search results
      state.searchResults.forEach((company) => {
        companiesMap.set(company._id, company)
      })

      return [...companiesMap.values()]
    },
  },
  mutations: {
    setSearchResults(state, results) {
      state.searchResults = results
    },
    addSelectedCompany(state, company) {
      if (!state.selectedCompanies.find((selectedCompany) => selectedCompany._id === company._id)) {
        state.selectedCompanies.push(company)
      }
    },
    addExcludedCompany(state, company) {
      if (!state.excludedCompanies.find((excludedCompany) => excludedCompany._id === company._id)) {
        state.excludedCompanies.push(company)
      }
    },
    clearSearchResults(state) {
      state.searchResults = []
    },
  },
  actions: {
    async searchCompanies({ commit }, payload) {
      try {
        const response = await CompanyService.search(payload)
        commit('setSearchResults', response.body.data)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
