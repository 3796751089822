var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Card",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("h4", { staticClass: "card-header-title" }, [
                    _vm._v("General"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("CustomSwitch", {
            attrs: {
              value: _vm.manualFallbackCallToAction.enabled,
              label: "Manual Fallback Call To Action",
            },
            on: { input: _vm.onManualFallbackCallToActionChange },
            scopedSlots: _vm._u([
              {
                key: "helperText",
                fn: function () {
                  return [
                    _vm._v(
                      " Enabling this will present a button on the Zero Search Results for Payroll screen and Authentication Error screen. This button will emit the "
                    ),
                    _c("code", [_vm._v("onClose")]),
                    _vm._v(" SDK event with data containing "),
                    _c("code", [_vm._v("reason")]),
                    _vm._v(": "),
                    _c("code", [_vm._v("manual-fallback")]),
                    _vm._v(". "),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("CustomSwitch", {
            attrs: {
              value: _vm.testCredentials,
              label: _vm.getProductionOnlyFeatureLabel("Test Credentials"),
              disabled: _vm.isSandbox,
            },
            on: { input: _vm.onTestCredentialsChange },
            scopedSlots: _vm._u([
              {
                key: "helperText",
                fn: function () {
                  return [
                    _vm._v(
                      " Disabling test credentials will prevent the usage of "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://docs.atomicfi.com/reference/transact-sdk#testing",
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          " test credentials outlined in the documentation. "
                        ),
                      ]
                    ),
                    _vm.isSandbox
                      ? _c("div", { staticClass: "small text-info mt-2" }, [
                          _vm._v(
                            " *This feature is only available in production. Test credentials are required in sandbox. "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm.isProduction
        ? _c(
            "Card",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "card-header-title" }, [
                          _vm._v("Conversion Optimization"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1657692061
              ),
            },
            [
              _c("p", { staticClass: "text-muted" }, [
                _c("small", [
                  _vm._v(
                    " To improve the Atomic experience and increase conversion rate, our team periodically runs experiments and surveys to make data driven decisions around modifications. Please opt in to help us make our product better for our shared customers. "
                  ),
                ]),
              ]),
              _c("p", { staticClass: "text-muted" }, [
                _c("small", [
                  _vm._v(" Controls for experiments and surveys have moved: "),
                  _c("ul", [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "settings-experiments" } } },
                          [_vm._v("Experiments")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "settings-surveys" } } },
                          [_vm._v("Surveys")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "Card",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("h4", { staticClass: "card-header-title" }, [
                    _vm._v("Deposit"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("FractionalDepositFeatureSettings", {
            attrs: {
              data: _vm.fractionalDepositSettings,
              fixedFractionalDepositLimitSettings:
                _vm.fixedFractionalDepositLimitSettings,
              percentFractionalDepositLimitSettings:
                _vm.percentFractionalDepositLimitSettings,
            },
            on: { input: _vm.onFractionalDepositChange },
          }),
          _c("CustomSwitch", {
            attrs: {
              value: _vm.manualDepositInstructions,
              helperText:
                "Allows your users\n      to view instructions to manually switch their direct deposit.",
              label: "Manual Instructions",
            },
            on: { input: _vm.onManualDepositInstructionsChange },
          }),
          _c("CustomSwitch", {
            attrs: {
              value: _vm.showTaskHistory,
              helperText:
                "Displays the status of recent direct deposit activity performed by the user.",
              label: "Show Task History",
            },
            on: { input: _vm.onShowTaskHistoryChange },
          }),
          _c("CustomSwitch", {
            attrs: {
              value: _vm.showDetectedIncomeSourcesFlow,
              helperText:
                "Display a pre-populated list of companies curated for the user.",
              label: "Show Detected Income Sources Flow",
            },
            on: { input: _vm.onShowDetectedIncomeSourcesFlowChange },
          }),
        ],
        1
      ),
      _c(
        "Card",
        {
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("h4", { staticClass: "card-header-title" }, [
                    _vm._v("Bounded Fractional Deposit"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("SettingsBoundedFractionalDeposit", {
            attrs: {
              data: {
                percent: _vm.percentFractionalDepositLimitSettings,
                fixed: _vm.fixedFractionalDepositLimitSettings,
              },
              enabled: _vm.fractionalDepositsEnabled,
            },
            on: {
              fixedInput: _vm.onBoundedFractionalDepositChangeFixed,
              percentInput: _vm.onBoundedFractionalDepositChangePercent,
            },
          }),
        ],
        1
      ),
      _vm.userLinkContinuousAccess && _vm.userLinkContinuousAccess.enabled
        ? _c(
            "Card",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "card-header-title" }, [
                          _vm._v("Continuous Access"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1876948013
              ),
            },
            [
              _c("SettingsFeaturesUserLinkMonitoring", {
                attrs: {
                  userLinkContinuousAccess: _vm.userLinkContinuousAccess,
                },
                on: {
                  input: function ($event) {
                    return _vm.onUserLinkChange($event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.hasScope(_vm.SCOPES.PAY_LINK)
        ? _c(
            "Card",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h4", { staticClass: "card-header-title" }, [
                          _vm._v("PayLink"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3775317036
              ),
            },
            [
              _c("SettingsFeaturesPayLink", {
                attrs: { data: _vm.payLink },
                on: {
                  input: function ($event) {
                    return _vm.onPayLinkChange($event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "Card",
        {
          attrs: { id: "search-rules" },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between",
                    },
                    [
                      _c("div", [
                        _c("h4", { staticClass: "card-header-title mb-0" }, [
                          _vm._v("Search Experiences"),
                        ]),
                        _c(
                          "small",
                          { staticClass: "text-muted d-block pt-2" },
                          [
                            _vm._v(
                              " Create custom search experiences tailored to your users and use cases. "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-primary",
                          on: { click: _vm.addSearchRule },
                        },
                        [_vm._v("New Experience")]
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("SearchRules", {
            attrs: { data: _vm.searchRulesData },
            on: {
              input: function ($event) {
                return _vm.onSearchRulesChange($event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "Card",
        {
          attrs: { bodyHasPadding: false },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("h4", { staticClass: "card-header-title" }, [
                    _vm._v(" Verify "),
                    _c("small", { staticClass: "text-muted d-block pt-2" }, [
                      _vm._v(
                        " Select desired fields. Fields marked with an * are required fields and will be included in every verify task. "
                      ),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "ul",
            { staticClass: "list-group" },
            _vm._l(_vm.verifyFields, function (groupDefinition, groupKey) {
              return _c(
                "li",
                { key: groupKey, staticClass: "list-group-item" },
                [
                  _c(
                    "h4",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle",
                          value: "collapse-" + groupKey,
                          expression: "`collapse-${groupKey}`",
                        },
                      ],
                    },
                    [
                      _c("ChevronRightIcon", {
                        staticClass: "toggle-icon",
                        attrs: { size: "1.2x" },
                      }),
                      _vm._v(" " + _vm._s(groupDefinition.label) + " "),
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    { attrs: { id: "collapse-" + groupKey } },
                    [
                      _c("FieldSelectionForm", {
                        attrs: {
                          fieldDefinitions: groupDefinition.fields,
                          value: _vm.outputsFieldSettings,
                          disableAll: _vm.isUpdating,
                        },
                        on: { input: _vm.onFieldSelectionChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }