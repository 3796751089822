import { PRODUCTS } from '@atomicfi/constants-shared'

const { DEPOSIT, SWITCH, VERIFY, PRESENT } = PRODUCTS

export const TASK_FILTER_PRODUCT_OPTIONS = {
  DEPOSIT,
  SWITCH,
  VERIFY,
  PRESENT,
  // we're hardcoding this locally because the shared package repo is blocked by vulnerabilities
  ACTION: 'action',
}
